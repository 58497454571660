import React, { useEffect, useState } from "react";
import OwnerSection from "./ownerComponent/ownerSection";
import groupCompany from "../images/Group.webp"
import screenshot from "../images/white.svg";
import offline from "../images/not-active.svg";
import check from "../images/online.webp";
import { useNavigate } from "react-router-dom";
import line from "../images/line.webp";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import useLoading from "../hooks/useLoading";
import axios from "axios";
import noResultFound from '../images/no-result-found.svg'
import Pusher from 'pusher-js';

function CompanyOwner() {

    const [lastScreenshot, setLastScreenshot] = useState(null)
    const [error, setError] = useState(false)
    const [activeUser, setActiveUser] = useState(null)
    const { loading, setLoading, loading2, setLoading2 } = useLoading()
    const navigate = useNavigate()
    const apiUrl = "https://zany-sneakers-hare.cyclic.cloud/api/v1";
    const token = localStorage.getItem('token');
    const [data, setData] = useState(null)
    const [searchResults, setSearchResults] = useState(null)
    const headers = {
        Authorization: "Bearer " + token,
    };

    // var pusher = new Pusher('334425b3c859ed2f1d2b', {
    //     cluster: 'ap2'
    // });

    // var channel = pusher.subscribe('ss-track');

    // channel.bind('my-user', (data) => {
    //     setActiveUser(data?.data)
    //     console.log("active user ===>", data.data);
    // });

    // useEffect(() => {
    //     var channel = pusher.subscribe('ss-track');
    //     channel.bind("new-ss", (data) => {
    //         setLastScreenshot(data?.data)
    //         console.log("new ss ===>", data);
    //     });
    //     return () => {
    //         channel.unbind("new-ss");
    //     };
    // }, [])

    // channel.bind('my-event', (data) => {
    //     console.log(JSON.stringify(data));
    // });

    const fetchData = async () => {
        setLoading(true)
        try {
            setLoading2(true)
            const response = await axios.get(`${apiUrl}/owner/getCompanyemployee`, {
                headers,
            })
            if (response.status) {
                setLoading(false)
                setTimeout(() => {
                    setLoading2(false)
                }, 1000);
                const onlineUsers = response.data?.onlineUsers?.length > 0 ? response.data?.onlineUsers : []
                const offlineUsers = response.data?.offlineUsers?.length > 0 ? response.data?.offlineUsers : []
                const allUsers = [...onlineUsers, ...offlineUsers];
                setData(allUsers.filter((f) => {
                    return f.isArchived === false && f.UserStatus === false
                }))
                console.log(response);
            }
        }
        catch (error) {
            setError(true)
            setLoading(false)
            setTimeout(() => {
                setLoading2(false)
            }, 1000);
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    function moveOnlineUsers(userId) {
        console.log(userId);
        navigate("/company-individual-user", {
            state: userId,
        });
    }

    function handleSearchEmployee(e) {
        setLoading2(true)
        const searchData = data?.filter((user, index) => {
            return user.userName.toLowerCase().includes(e.target.value.toLowerCase().trim())
        })
        setSearchResults(searchData)
        setTimeout(() => {
            setLoading2(false)
        }, 1000);
    }

    useEffect(() => {
        if (data !== null && data.length > 0) {
            setSearchResults(data)
        }
    }, [data])

    return (
        <>
            <div className="container">
                <div>
                    <div className="userHeader">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                            <div><img src={groupCompany} /></div>
                            <h5>All Employee {data?.totalUsers}</h5>
                        </div>
                        <div>
                            <input
                                onChange={(e) => handleSearchEmployee(e)}
                                placeholder="Search employee"
                                style={{
                                    width: "300px",
                                    borderRadius: "5px",
                                    padding: "12px 20px",
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                    border: "none",
                                    outline: "none"
                                }} />
                        </div>
                    </div>
                    <div className="mainwrapper">
                        <div className="systemOwnerContainer">
                            <div className="dashheadings">
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop">Employee</p>
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop textalign">Last active</p>
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop textalign">Today</p>
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop textalign">Yesterday</p>
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop textalign">This week</p>
                                <p style={{ fontSize: "18px", color: "#0D3756" }} className="dashheadingtop textalign">This Month</p>
                            </div>
                            <div className="bgColorChangeGreen" style={{ marginTop: "20px" }}>
                                {loading ? <Skeleton count={1} height="100vh" style={{ margin: "0 0 10px 0" }} /> :
                                    searchResults !== null && searchResults?.length > 0 ? searchResults?.sort((a, b) => {
                                        const timestampA = b.recentScreenshot?.createdAt || 0;
                                        const timestampB = a.recentScreenshot?.createdAt || 0;
                                        if (timestampA === 0 && timestampB === 0) return 0;
                                        if (timestampA === 0) return -1;
                                        if (timestampB === 0) return 1;
                                        return timestampA - timestampB;
                                    }).map((user, index) => {
                                        return loading2 ? (
                                            <Skeleton count={1} height="107px" style={{ margin: "0 0 10px 0" }} />
                                        ) : (
                                            <div className="dashsheadings" key={user.userId}>
                                                <div className="companyNameverified">
                                                    <img src={user?.userId === activeUser?._id && activeUser?.isActive === true ? check : user?.isActive === true ? check : offline} alt="Verified" />
                                                    <h5 className="dashCompanyName">{user?.userName}</h5>
                                                </div>
                                                <div className="companyNameverified lastActive" style={{ width: "100%" }}>
                                                    <img
                                                        onClick={() => moveOnlineUsers(user.userId)}
                                                        className="screenShotPreview"
                                                        src={
                                                            lastScreenshot?.user_id === user?.userId ?
                                                                lastScreenshot?.key :
                                                                user?.recentScreenshot ? user?.recentScreenshot?.key : screenshot
                                                        }
                                                        alt="Screenshot"
                                                    />
                                                    <p className="dashheadingtop">
                                                        ({user?.minutesAgo === "0 minute ago" || user?.minutesAgo === "-1 minute ago" ? "a minute ago" : user?.minutesAgo})
                                                    </p>
                                                </div>
                                                <div className="nameVerified">
                                                    <p className="dashheadingtop textalign">{user?.totalHours?.daily}</p>
                                                    <p className="screenShotAmount" style={{ color: user.isActive === true && "#28659C" }}>${user?.billingAmounts?.daily}</p>
                                                </div>
                                                <div className="nameVerified">
                                                    <p className="dashheadingtop textalign">{user?.totalHours?.yesterday}</p>
                                                    <p className="screenShotAmount" style={{ color: user.isActive === true && "#28659C" }}>${user?.billingAmounts?.yesterday}</p>
                                                </div>
                                                <div className="nameVerified">
                                                    <p className="dashheadingtop textalign">{user?.totalHours?.weekly}</p>
                                                    <p className="screenShotAmount" style={{ color: user.isActive === true && "#28659C" }}>${user?.billingAmounts?.weekly}</p>
                                                </div>
                                                <div className="nameVerified">
                                                    <p className="dashheadingtop textalign">{user?.totalHours?.monthly}</p>
                                                    <p className="screenShotAmount" style={{ color: user.isActive === true && "#28659C" }}>${user?.billingAmounts?.monthly}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        error === true ? (
                                            <Skeleton count={1} height="100vh" style={{ margin: "0 0 10px 0" }} />
                                        ) : (
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                minHeight: "80vh"
                                            }}>
                                                <img style={{ width: "50%" }} src={noResultFound} />
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <img className="userDetailLine" src={line} />
            </div>
        </>
    )
}

export default CompanyOwner;